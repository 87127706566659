<template>
  <va-card class="form-card">
    <va-alert closeable @click="closeAlert" v-bind:hidden="alertHidden" v-bind:color="alertColor" class="mb-4">
      {{ alertMessage }}
    </va-alert>
    <va-file-upload class="file-uploader" v-model="files" dropzone type="gallery"/>
    <va-form style="width: 300px;" @submit.prevent="handleSubmit" tag="form">
      <va-input
        label="Fa Name"
        v-model="faName"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        class="mt-3"
        label="En Name"
        v-model="enName"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        class="mt-3"
        label="Phone"
        v-model="phone"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        class="mt-3"
        label="City"
        v-model="city"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        class="mt-3"
        label="Fa Address"
        v-model="address"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-input
        class="mt-3"
        label="LatLng"
        v-model="latlng"
        :rules="[value => (value && value.length > 0) || 'Field is required']"
      />
      <va-switch v-model="exteriorSpace" v-on:click="exterior" left-label class="mr-4 card-child"> exterior space:
      </va-switch>
      <va-switch v-model="smoking" v-on:click="exterior" left-label class="mr-4 card-child"> smoking:</va-switch>
      <br/>
      <va-button type="submit" class="mt-3">
        submit
      </va-button>

    </va-form>
  </va-card>
</template>

<script>
import * as tus from "tus-js-client";
import axios from "axios";

export default {
  name: "AddForm",
  methods: {
    closeAlert(){
      this.alertHidden = true
    },
    handleSubmit() {

      try {
        const response = (axios.post(`${process.env.VUE_APP_REST_INGESTION_HOST}/restaurant`,
          {
            'image': '',
            'fa_name': this.faName,
            'en_name': this.enName,
            'phone': this.phone,
            'city': this.city,
            'address': this.address,
            'lat_lng': this.latlng,
            'exterior_space': this.exteriorSpace,
            'smoking': this.smoking,
          }
          , {
            headers: {
              'Content-Type': 'application/json',
              'token': 'hellothisistoken',
            },
          }).then(res => {

          // this.restID = response.data.data
          this.restID = res.data.data

          const file = this.files[0]
          const upload = new tus.Upload(file, {
            endpoint: `${process.env.VUE_APP_TUS_REST_HOST}`,
            retryDelays: [0, 3000, 5000, 10000, 20000],
            metadata: {
              d: this.restID,
            },
            onError: function (error) {
              console.log("Failed because: " + error)
            },
            onProgress: function (bytesUploaded, bytesTotal) {
              const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
              console.log(bytesUploaded, bytesTotal, percentage + "%")
            },
            onSuccess: function () {
              console.log("Download %s from %s", upload.file.name, upload.url)
            }
          })

          // Check if there are any previous uploads to continue.
          upload.findPreviousUploads().then(function (previousUploads) {
            // Found previous uploads so we select the first one.
            if (previousUploads.length) {
              upload.resumeFromPreviousUpload(previousUploads[0])
            }

            // Start the upload
            upload.start()
          })


        }))

        this.restID = '',
          this.faName = '',
          this.enName = '',
          this.phone = '',
          this.city = '',
          this.address = '',
          this.latlng = '',
          //this.files = [],
          this.exteriorSpace = false,
          this.smoking = false,

          this.alertColor = 'success'
        this.alertMessage = 'add successfully'
        this.alertHidden = false

        //this.$router.push({name: 'show',})
      } catch (e) {
        console.log('erororor')
        console.log(e)
        this.alertColor = 'danger'
        this.alertMessage = 'something wrong'
        this.alertHidden = false
        this.errors.push(e)
      }


      // const file = this.files[0]
      // const upload = new tus.Upload(file, {
      //   endpoint: "http://localhost:1080/files/",
      //   retryDelays: [0, 3000, 5000, 10000, 20000],
      //   metadata: {
      //     d: this.restID,
      //   },
      //   onError: function(error) {
      //     console.log("Failed because: " + error)
      //   },
      //   onProgress: function(bytesUploaded, bytesTotal) {
      //     const percentage = (bytesUploaded / bytesTotal * 100).toFixed(2)
      //     console.log(bytesUploaded, bytesTotal, percentage + "%")
      //   },
      //   onSuccess: function() {
      //     console.log("Download %s from %s", upload.file.name, upload.url)
      //   }
      // })
      //
      // // Check if there are any previous uploads to continue.
      // upload.findPreviousUploads().then(function (previousUploads) {
      //   // Found previous uploads so we select the first one.
      //   if (previousUploads.length) {
      //     upload.resumeFromPreviousUpload(previousUploads[0])
      //   }
      //
      //   // Start the upload
      //   upload.start()
      // })


    },
    exterior() {
      // this.exteriorSpace = !this.exteriorSpace
      console.log(this.exteriorSpace)
    }
  },
  data() {
    return {
      restID: '',
      faName: '',
      enName: '',
      phone: '',
      city: '',
      address: '',
      latlng: '',
      files: [],
      exteriorSpace: false,
      smoking: false,

      alertColor: '',
      alertMessage: '',
      alertHidden: true,
    }
  },
}


</script>

<style scoped>
.form-card {
  background: blue;
  background-color: blue;
  color: #34495e;
  margin: 10px;
  padding: 40px;

}

.card-child {
  margin: 10px;
  align-items: baseline;
  align-self: baseline;
  alignment: center;
  align-content: center;
}

.file-uploader {
  margin: 50px;
}
</style>



