<template>
  <div class="dashboard">
    <add-form></add-form>
  </div>
</template>

<script>
import AddForm from "@/pages/admin/restaurant/add/AddForm";

export default {
  name: "restaurant",
  components: {
    AddForm,
  },
}
</script>

<style scoped>

</style>
